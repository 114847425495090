import React from 'react';
import HeaderOne from '../../Header/HeaderOne';
import FooterOne from '../../Footer/FooterOne';
import BannerOne from '../../HomeOne/BannerOne/BannerOne';
import Service from '../../HomeOne/Service/Service';
import AboutOne from '../../HomeOne/AboutOne/AboutOne';
import Choose from '../../HomeOne/Choose/Choose';
import Testimonial from '../../HomeOne/Testimonial/Testimonial';
import Contact from '../../HomeOne/contact'; 
import Portfolio from '../../HomeTwo/Portfolio/Portfolio';
import Tabs from '../Partnerships/tabs';
import Experties from '../Experties/experties';
import Clients from '../Clients/clients';
import { pageTitle } from '../../PageTitle';


const HomeOne = () => {
    pageTitle('SiteGuiders');
    return (
        <>
            <HeaderOne></HeaderOne>
            <BannerOne></BannerOne>
            {/* <Service></Service> */}
            {/* <AboutOne></AboutOne> */}
            {/* <Choose></Choose> */}
            {/* <Team></Team> */}
            {/* <Testimonial></Testimonial> */}
            {/* <Brand></Brand> */}
            {/* <Portfolio></Portfolio> */}
            {/* <Portfolio></Portfolio> */}
            {/* <Blog></Blog> */}
            <Clients></Clients>
            <Experties></Experties>
            <Tabs></Tabs>
            <Contact></Contact>
            <FooterOne></FooterOne>
        </>
    );
};

export default HomeOne;