import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
// import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import Webdevheader from '../HomeOne/Servicescomp/Webdevheader';
import Webredefines from '../HomeOne/Servicescomp/Webredefines';
import WebdevDelivery from '../HomeOne/Servicescomp/WebdevDeliverys';
import { Link } from 'react-router-dom';

// Import FontAwesome icons
// import '@fortawesome/fontawesome-free/css/all.min.css';

const Webdevelopment = () => {
    pageTitle('Web Development');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('Form submitted:', formData);

        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    

    return (
        <>
            <HeaderOne />
            
            <Webdevheader></Webdevheader>
            <Webredefines></Webredefines>
            <WebdevDelivery></WebdevDelivery>
            {/* <Contact></Contact> */}
            <FooterOne />
        </>
    );
};

export default Webdevelopment;
