import React, { useState } from 'react';
import Rictv from "../../../assets/images/resource/rictv.png";
const Tabs = () => {
    const [activeTab, setActiveTab] = useState(1); // Default active tab

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <section className='contact__page p_relative section-partnerships'>
    <div className="container-medium">
        <div className="padding-vertical_section">
        <div className="form__title">
            <div className="title__data">
                <div className="sub__title text-center">
                    <h4>PARTNERSHIPS</h4>
                </div>
                <div className="title text-center">
                    <h2>About some of our projects</h2>
                    <div className="margin-top margin-medium"><p data-w-id="fb3ac194-9ec9-8d97-0778-85c2a36b87c8" className="text-size-large text-color-light text-center">A quick peek at our partnerships and what we are currently doing for them.</p></div>
                </div>
            </div>
        </div>
        <div className="tabs">
            <div className="tabs-menu w-tab-menu">
                <a
                    data-w-tab="Tab 1"
                    className={`tab-links w-inline-block w-tab-link ${activeTab === 1 ? 'w--current' : ''}`}
                    onClick={() => handleTabClick(1)}
                >
                    <img loading="lazy" src={Rictv} alt="grindr brand logo" className="tab-image" />
                </a>
                <a
                    data-w-tab="Tab 1"
                    className={`tab-links w-inline-block w-tab-link ${activeTab === 2 ? 'w--current' : ''}`}
                    onClick={() => handleTabClick(2)}
                >
                    <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" />
                </a>
                <a
                    data-w-tab="Tab 1"
                    className={`tab-links w-inline-block w-tab-link ${activeTab === 3 ? 'w--current' : ''}`}
                    onClick={() => handleTabClick(3)}
                >
                    <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" />
                </a>
                <a
                    data-w-tab="Tab 1"
                    className={`tab-links w-inline-block w-tab-link ${activeTab === 4 ? 'w--current' : ''}`}
                    onClick={() => handleTabClick(4)}
                >
                    <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" />
                </a>
            </div>

            <div className="tabs-content w-tab-content">
                {activeTab === 1 && (
                    <div className="tab-pane w-tab-pane w--tab-active" id="tab1">
                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-ms-12">
                             <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" /> 
                            </div>
                            <div className="col-lg-6 col-md-6 col-ms-12">
                                <div id="w-node-_0b1c2ce3-d368-c933-7596-5a95f67026ba-f67026ad" class="tab-content-item">
                                    <h3 class="h3-size-large text-color-light">Rich tv</h3>
                                    <div class="margin-top margin-small">
                                        <p> Rich TV is the traders’ favorite community for help, support and guidance on investing smartly in stock and crypto markets. With an enormous pool of educational resources, penny stocks with huge upside, round-the-clock market data, latest news, in-depth analysis and insights, and tools that facilitate efficient trading, Rich TV remains a 360-degree platform for investors looking to grow their wealth significantly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 2 && (
                    <div className="tab-pane w-tab-pane w--tab-active" id="tab2">
                    <div className='row'>
                        <div className="col-lg-6 col-md-6 col-ms-12">
                         <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" /> 
                        </div>
                        <div className="col-lg-6 col-md-6 col-ms-12">
                            <div id="w-node-_0b1c2ce3-d368-c933-7596-5a95f67026ba-f67026ad" class="tab-content-item">
                                <h3 class="h3-size-large text-color-light">Rich tv</h3>
                                <div class="margin-top margin-small">
                                    <p> Rich TV is the traders’ favorite community for help, support and guidance on investing smartly in stock and crypto markets. With an enormous pool of educational resources, penny stocks with huge upside, round-the-clock market data, latest news, in-depth analysis and insights, and tools that facilitate efficient trading, Rich TV remains a 360-degree platform for investors looking to grow their wealth significantly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                {activeTab === 3 && (
                    <div className="tab-pane w-tab-pane w--tab-active" id="tab3">
                    <div className='row'>
                        <div className="col-lg-6 col-md-6 col-ms-12">
                         <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" /> 
                        </div>
                        <div className="col-lg-6 col-md-6 col-ms-12">
                            <div id="w-node-_0b1c2ce3-d368-c933-7596-5a95f67026ba-f67026ad" class="tab-content-item">
                                <h3 class="h3-size-large text-color-light">Rich tv</h3>
                                <div class="margin-top margin-small">
                                    <p> Rich TV is the traders’ favorite community for help, support and guidance on investing smartly in stock and crypto markets. With an enormous pool of educational resources, penny stocks with huge upside, round-the-clock market data, latest news, in-depth analysis and insights, and tools that facilitate efficient trading, Rich TV remains a 360-degree platform for investors looking to grow their wealth significantly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                {activeTab === 4 && (
                    <div className="tab-pane w-tab-pane w--tab-active" id="tab4">
                    <div className='row'>
                        <div className="col-lg-6 col-md-6 col-ms-12">
                         <img loading="lazy" src={Rictv} alt="grindr brand logo" class="tab-image" /> 
                        </div>
                        <div className="col-lg-6 col-md-6 col-ms-12">
                            <div id="w-node-_0b1c2ce3-d368-c933-7596-5a95f67026ba-f67026ad" class="tab-content-item">
                                <h3 class="h3-size-large text-color-light">Rich tv</h3>
                                <div class="margin-top margin-small">
                                    <p> Rich TV is the traders’ favorite community for help, support and guidance on investing smartly in stock and crypto markets. With an enormous pool of educational resources, penny stocks with huge upside, round-the-clock market data, latest news, in-depth analysis and insights, and tools that facilitate efficient trading, Rich TV remains a 360-degree platform for investors looking to grow their wealth significantly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
        </div>
</div>
</section>
    );
};

export default Tabs;
