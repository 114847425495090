import React from 'react';
import { Link } from 'react-router-dom';
import Headrimg from "../../../assets/images/resource/web_development.959bb6a0.png";
// import contact from "contact.jpg";


const Webdevheader = () => {
  return (
    <section className="about__section p_relative see__pad webdevsection">
      {/* <div className="pattern-layer">
        <div
          className="pattern-2 p_absolute"
          data-parallax='{"x": -50}'
          style={{ backgroundImage: `url(${BannerBg})` }}
        ></div>
      </div> */}
      {/* <div className="boild__text">
      <h1 style={{ textAlign: 'center' }}>SiteGuiders</h1>
      </div> */}
      <div className="auto-container">
        <div className="row">
          
          
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              {/* <div className="sub__title">
                <h4>About Company</h4>
              </div> */}
              <div className="title two">
                <h2>
                    Web Development <br></br> Services
                </h2>
              </div>
              <div className="texts">
                <p>
                As a seasoned web development company, we launch innovative websites as per the needs of our clients, so as to help them expand their user base, stamp their authority in the industry, increase conversions, and accomplish other goals crucial to their business.
                </p>
              </div>
              <div className="btn-box">
                <Link to="/about" className="theme-btn theme-btn-one">
                  Get Free Consultation
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6 col-md-12">
            <div className="about__block p_relative">
              <figure
                className="wow zoomIn animated img-right"
                data-wow-delay="100ms"
                data-wow-duration="1500ms"
              >
                <img src={Headrimg} alt="" />
              </figure>
              {/* <div
                className="funfact__content about"
                style={{ backgroundImage: `url(${shape1})` }}
              >
                <div className="count-outer count-box">
                  <h1 className="count-text">
                  <CountUp start={0} end={10} /><span>K+</span>
                  </h1>
                  
                </div>
                <p style={{ textAlign: 'center' }}>Projects Completed</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Webdevheader;
