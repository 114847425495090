import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

// Import all the logos from 1 to 50
import Logo1 from "../../../assets/logos/1.png";
import Logo2 from "../../../assets/logos/2.png";
import Logo3 from "../../../assets/logos/3.png";
import Logo4 from "../../../assets/logos/4.png";
import Logo5 from "../../../assets/logos/5.png";
import Logo6 from "../../../assets/logos/6.png";
import Logo7 from "../../../assets/logos/7.png";
import Logo8 from "../../../assets/logos/8.png";
import Logo9 from "../../../assets/logos/9.png";
import Logo10 from "../../../assets/logos/10.png";
import Logo11 from "../../../assets/logos/11.png";
import Logo12 from "../../../assets/logos/12.png";
import Logo13 from "../../../assets/logos/13.png";
import Logo14 from "../../../assets/logos/14.png";
import Logo15 from "../../../assets/logos/15.png";
import Logo16 from "../../../assets/logos/16.png";
import Logo17 from "../../../assets/logos/17.png";
import Logo18 from "../../../assets/logos/18.png";
import Logo19 from "../../../assets/logos/19.png";
import Logo20 from "../../../assets/logos/20.png";
import Logo21 from "../../../assets/logos/21.png";
import Logo22 from "../../../assets/logos/22.png";
import Logo23 from "../../../assets/logos/23.png";
import Logo24 from "../../../assets/logos/24.png";
import Logo25 from "../../../assets/logos/25.png";
import Logo26 from "../../../assets/logos/26.png";
import Logo27 from "../../../assets/logos/27.png";
import Logo28 from "../../../assets/logos/28.png";
import Logo29 from "../../../assets/logos/29.png";
import Logo30 from "../../../assets/logos/30.png";
import Logo31 from "../../../assets/logos/31.png";
import Logo32 from "../../../assets/logos/32.png";
import Logo33 from "../../../assets/logos/33.png";
import Logo34 from "../../../assets/logos/34.png";
import Logo35 from "../../../assets/logos/35.png";
import Logo36 from "../../../assets/logos/36.png";
import Logo37 from "../../../assets/logos/37.png";
import Logo38 from "../../../assets/logos/38.png";
import Logo39 from "../../../assets/logos/39.png";
import Logo40 from "../../../assets/logos/40.png";
import Logo41 from "../../../assets/logos/41.png";
import Logo42 from "../../../assets/logos/42.png";
import Logo43 from "../../../assets/logos/43.png";
import Logo44 from "../../../assets/logos/44.png";
import Logo45 from "../../../assets/logos/45.png";
import Logo46 from "../../../assets/logos/46.png";
import Logo47 from "../../../assets/logos/47.png";
import Logo48 from "../../../assets/logos/48.png";
import Logo49 from "../../../assets/logos/49.png";
import Logo50 from "../../../assets/logos/50.png";

// Store all the logos in an array
const logos = [
  Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10,
  Logo11, Logo12, Logo13, Logo14, Logo15, Logo16, Logo17, Logo18, Logo19, Logo20,
  Logo21, Logo22, Logo23, Logo24, Logo25, Logo26, Logo27, Logo28, Logo29, Logo30,
  Logo31, Logo32, Logo33, Logo34, Logo35, Logo36, Logo37, Logo38, Logo39, Logo40,
  Logo41, Logo42, Logo43, Logo44, Logo45, Logo46, Logo47, Logo48, Logo49, Logo50
];

const Clients = () => {
  return (
    <section className="contact__page p_relative section-clients">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-ms-12">
            <div className="contact__center">
              <div className="form__title">
                <div className="title__data">
                  <div className="title text-center">
                    <h2 >Our Esteemed Clients</h2>
                  </div>
                </div>
              </div>
              <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events clients-logo">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={100}
                  slidesPerView={4}
                  navigation
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                >
                  {logos.map((logo, index) => (
                    <SwiperSlide key={index}>
                      <div className="slide_box">
                        <img
                          src={logo}
                          loading="lazy"
                          alt={`client-logo-${index + 1}`}
                          className="client-logo"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
