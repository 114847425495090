import React from 'react';
import { Link } from 'react-router-dom';
import Checkmark from "../../../assets/images/resource/check-mark.png";
// import contact from "contact.jpg";


const Webredefines = () => {
  return (
    <section className="about__section p_relative see__pad webdevsection">
      <div className="auto-container">
        <div className="row">
        <div className="col-lg-5 col-md-12">
            <div className="about__block p_relative">
              <div className='bgcst-dark point-container'>
                <div className="bgcst-dark supercharge_list-title">
                  <h3 className="text-color-light">Why companies reach out to us seeking web development solutions</h3>
                  </div>
                  <div className='list-cion bgcst-dark'>
                  <img src={Checkmark} alt="" /><span>To build a site that leaves a strong first impression of their brand</span>
                  </div>
                  <div className='list-cion bgcst-dark'>
                  <img src={Checkmark} alt="" /><span>Cover all functional & aesthetic bases</span>
                  </div>
                  <div className='list-cion bgcst-dark'>
                  <img src={Checkmark} alt="" /><span>Attract more customers</span>
                  </div>
                  <div className='list-cion bgcst-dark'>
                  <img src={Checkmark} alt="" /><span>Generate more sales qualified leads</span>
                  </div>
                  <div className='list-cion bgcst-dark'>
                  <img src={Checkmark} alt="" /><span>Enjoy longer time spent on their site</span>
                  </div>
                  <div className='list-cion bgcst-dark'>
                  <img src={Checkmark} alt="" /><span>Improve user engagement on their site</span>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6 col-md-12">
            <div className="about__text__block">
              <div className="title two">
                <h2>
                We Create Websites That Redefine Your Online Presence
                </h2>
              </div>
              <div className="texts">
                <p>
                Services Ground’s Web Development Services enable you to get your new website up and running in a timely, reliable, and cost-effective manner on any cloud infrastructure of your choice. We have ample experience developing and managing websites of all sorts, be it for corporates, E-commerce ventures, blogs, etc. Thanks to a large pool of our IT experts that can handle the most complex requirements of your project at every level of the Software Development Lifecycle, you can be assured that your website will always deliver stand-out results.
                </p><p>
We specialize in a vast range of web-development technologies, which means that your website will be built on one that is best suited to your requirements and traffic expectations. The development options we offer you are endless. From utilizing your existing CMS to building a customized solution from the ground up. By providing continued support and maintenance, we as your web development agency, make sure that we facilitate you at all times in your quest for digital excellence.
                </p>
              </div>
              <div className="btn-box">
                <Link to="/about" className="theme-btn theme-btn-one">
                  Get Free Consultation
                </Link>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </section>
  );
};

export default Webredefines;
