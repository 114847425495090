import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, loop} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';


const Contact = () => {
    //   pageTitle('Contact');
      const handleSubmitOne = (event) => {
        event.preventDefault(); 
      };
    
  return (
    
 
        <section className="contact__page p_relative see__pad">
            <div className="auto-container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-ms-12">
                        <div className="contact__left">
                            <div className="form__title">
                                <div className="title__data">
                                    <div className="sub__title">
                                        <h4>CONTACT US</h4>
                                    </div>
                                    <div className="title">
                                        <h2>Get Free Consultation</h2>
                                        <div className="margin-top margin-medium"><p data-w-id="fb3ac194-9ec9-8d97-0778-85c2a36b87c8" className="text-size-large text-color-light">We’ll reach out to you shortly.</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events slidertes">
                            <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            slidesPerView={1}
                            navigation 
                            loop={true}
                            autoplay={{
                                delay: 3000, 
                                disableOnInteraction: true, 
                            }}
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            >
                                <SwiperSlide>
                                    <div className="margin-top margin-medium slide-box">
                                        <p className="testimonials-paragraph mb-24">Experience with Services Ground is beyond my expectations,They had a high level of technical proficiency and were able to handle complex tasks. Their ability to think beyond the code is amazing and delivery time is also very fast and highly recommended.</p>
                                        <div className="testimonial-author_component slide-author">
                                            <div className="margin-small slide-box-child">
                                                <div className="testimonial-author-name slide-author-name">Naoufal Moudan</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="margin-top margin-medium slide-box">
                                        <p className="testimonials-paragraph mb-24">Experience with Services Ground is beyond my expectations,They had a high level of technical proficiency and were able to handle complex tasks. Their ability to think beyond the code is amazing and delivery time is also very fast and highly recommended.</p>
                                        <div className="testimonial-author_component slide-author">
                                            <div className="margin-small slide-box-child">
                                                <div className="testimonial-author-name slide-author-name">Naoufal Moudan</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="margin-top margin-medium slide-box">
                                        <p className="testimonials-paragraph mb-24">Experience with Services Ground is beyond my expectations,They had a high level of technical proficiency and were able to handle complex tasks. Their ability to think beyond the code is amazing and delivery time is also very fast and highly recommended.</p>
                                        <div className="testimonial-author_component slide-author">
                                            <div className="margin-small slide-box-child">
                                                <div className="testimonial-author-name slide-author-name">Naoufal Moudan</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="contact__right">
                            
                            <div className="form-inner">
                                <form onSubmit={handleSubmitOne} method="post" action="contact.php" className="default-form"> 
                                    <div className="row clearfix">
                                        <div className="col-xl-12 form-group">
                                            <label for="select-subject-2" class="form-labels">Subject</label>
                                            <select id="select-subject-2" name="select-subject-2" data-name="Select Subject 2" className="form-fields w-select">
                                                <option value="">Select one...</option>
                                                <option value="Web Development">Web Development</option>
                                                <option value="Mobile App Development">Mobile App Development</option>
                                                <option value="Quality Assurance Services">Quality Assurance Services</option>
                                                <option value="Email Marketing">Email Marketing</option>
                                                <option value="Digital Marketing">Digital Marketing</option>
                                                <option value="SEO">SEO</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="UI UX">UI &amp; UX Design</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-12 form-group">
                                            <label for="FULLNAME" className="form-labels">Name</label>
                                            <input type="text" className="form-fields w-input" maxlength="256" name="FULLNAME" data-name="FULLNAME" placeholder="" id="FULLNAME" required=""/>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label for="email" className="form-labels">Email Address</label>
                                            <input type="email" className="form-fields w-input" maxlength="256" name="email" data-name="Email" placeholder="" id="email" required=""/>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label for="Phone" className="form-labels">Phone</label>
                                            <input type="tel" className="form-fields w-input" maxlength="256" name="Phone" data-name="Phone" placeholder="" id="Phone" required=""/>
                                        </div>
                                        <div className="ccol-xl-12 col-lg-12 form-group">
                                            <label for="Message" className="form-labels">Message</label>
                                            <textarea  maxlength="5000" id="Message" name="Message" data-name="Message" required="" className="form-fields is-text-area w-input"></textarea>
                                        </div>
                                        <div className="col-xl-12 form-group message-btn btn-box">
                                            <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
};

export default Contact;
