import React from 'react';
import { Link } from 'react-router-dom';
import Headrimg from "../../../assets/images/resource/web_development.959bb6a0.png";



const WebdevDelivery = () => {
  return (
    <section className="about__section p_relative see__pad webdevsection">
     
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="about__text__block">
                <div className="why-devlane-item">
                    <div className="why-devlane-item-text-container">
                        <h3 className="text-color-light"><strong>Web development project planning</strong></h3>
                    </div>
                </div>
                <div className="margin-top margin-xsmall max-para">
                    <p className="text-size-18px">Establishing project requirements, outlining the scope of the website and coming up with a high-level project plan with estimated budget &amp; time required for completion.</p>
                </div>
                <div class="why-devlane-item is-indented-2-level is-remove-indent">
                    
                    <div className="why-devlane-item-text-container">
                        <h3 className="text-color-light"><strong>UX and UI design</strong></h3>
                    </div>
                </div>
                <div className="margin-top margin-xsmall max-para tow">
                    <p className="text-size-18px">Using client-side &amp; server-side programming to develop the site.</p>
                </div>
                <div className="why-devlane-item is-remove-indent">
                    
                    <div className="why-devlane-item-text-container">
                        <h3 className="text-color-light"><strong>Quality Assurance</strong></h3>
                    </div>
                </div>
                <div className="margin-top margin-xsmall max-para">
                    <p className="text-size-18px">Performing a variety of tests to verify that your website's speed, stability, and security are all intact.</p>
                </div>
                <div className="btn-box">
                    <Link to="/about" className="theme-btn theme-btn-one">
                    Get Free Consultation
                    </Link>
                </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-4 col-md-12">
            <div className="about__text__block">
              <div className="title two">
                <h2>
                How we deliver this service
                </h2>
              </div>
              <div className="texts">
                <p>
                Creating a user-friendly navigation and visually attractive user interfaces for your website that leads to greater conversions.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebdevDelivery;
