import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, loop } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import shap1 from "../../../assets/images/banner/banner-shap-01.png";
import BannerBg from "../../../assets/images/resource/header-image-background.png";
import Graphic from "../../../assets/images/resource/Graphic.png";
import DigitalMarketing from "../../../assets/images/resource/Digital_Marketing-new.png";
import QAEngineer from "../../../assets/images/resource/QA_Engineer.png";
import BackEnd from "../../../assets/images/resource/Back-End.png";
import FrontEnd from "../../../assets/images/resource/Front-End.png";
import Content from "../../../assets/images/resource/Content-new.png";

// import Banner1 from "../../../assets/images/banner/banner-01.png";
// import C2 from "../../../assets/images/resource/02.png";
import { Parallax } from 'react-parallax';

const infoimages = [
  Graphic, DigitalMarketing, QAEngineer, BackEnd, FrontEnd, Content
];
const BannerOne = () => {
  
  useEffect(() => {
    const text_2 = document.querySelector(".text_2");
    if (text_2) {
      text_2.innerHTML = text_2.innerText
        .split("")
        .map(
          (char, i) => `<span style="transform:rotate(${i * 10.3}deg)">${char}</span>`
        )
        .join("");
    }
  }, []);
  return (
    <section className="banner__one section-header">
      {/* <div className="pattern-layer">
      <Parallax offset={0} speed={2.8} bgImage={shap1} strength={100} className="banner__icon pattern-1 p_absolute"></Parallax>
      </div> */}
      <div className="banner__data p_relative">
        {/* <div className="banner__bg" style={{ backgroundImage: `url(${BannerBg})` }}></div> */}
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="banner__left">
                {/* <div className="sub__title">
                  <h4>Better Future</h4>
                </div> */}
                <div className="banner__title">
                  <h1>
                    IT Solutions for <span>Businesses</span>{' '}
                  </h1>
                </div>
                <div className="text">
                  <p>Over the years, we’ve partnered with both big companies and new startups. We help businesses like yours increase profits by boosting awareness, driving website traffic, connecting with customers, and growing sales.</p>
                </div>
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> Contact Us
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-2"></div> */}
            <div className="col-lg-6 col-md-12 ">
              <div className="banner__right p_relative header-banner" style={{ backgroundImage: `url(${BannerBg})` }}>
              <Swiper
                  modules={[Navigation, Autoplay]}
                  // spaceBetween={100}
                  slidesPerView={1}
                  loop={true}
                  // navigation
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                  }}
                >
                  {infoimages.map((infoimages, index) => (
                    <SwiperSlide key={index}>
                      <div className="slide_box infoimages">
                        <img
                          src={infoimages}
                          loading="lazy"
                          alt={`infoimages-${index + 1}`}
                          className="infoimages"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerOne;
