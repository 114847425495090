import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import Ecommerce from "../../../assets/icons/Ecommerce.json";
import Construction from "../../../assets/icons/Construction.json";
import Fitnesswellness from "../../../assets/icons/Fitness-wellness.json";
import Newsmedia from "../../../assets/icons/News-media.json";
import Stocksryptocurrency from "../../../assets/icons/Stocks-ryptocurrency.json";
import Digitalmarketing from "../../../assets/icons/Digitalmarketing.json";
import Restaurants from "../../../assets/icons/Restaurants.json";
import Esports from "../../../assets/icons/Esports.json";
import Realestate from "../../../assets/icons/Realestate.json";

const Experties = () => {
    

    return (
        <section className="about__section p_relative see__pad">
      
      <div className="auto-container">
        <div className="row experties-icons">
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              <div className="title two">
                <h2>
                Unleashing Business Potential with Expertise
                </h2>
              </div>
              <div className="texts">
                <p>
                With a proven track record of excellence across diverse industries, Services Ground is your trusted partner for unparalleled solutions. Our extensive industry expertise empowers us to conquer any business challenge with confidence and precision.
                </p>
              </div>
              <div className="btn-box">
                <Link to="/about" className="theme-btn theme-btn-one">
                  <i className="icon-02"></i> About more
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6 col-md-12">
            <div className="about__block p_relative">
              <div className='row experties-icons'>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Ecommerce} loop={true} />   
                    <strong className="bold-text">Ecommerce</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Restaurants} loop={true} />   
                    <strong className="bold-text">Restaurants</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Realestate} loop={true} />   
                    <strong className="bold-text">Real Estate</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Construction} loop={true} />   
                    <strong className="bold-text">Construction</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Stocksryptocurrency} loop={true} />   
                    <strong className="bold-text">Stocks & Cryptocurrency</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Fitnesswellness} loop={true} />   
                    <strong className="bold-text">Fitness & Wellness</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Esports} loop={true} />   
                    <strong className="bold-text">Esports</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie animationData={Digitalmarketing} loop={true} />   
                    <strong className="bold-text">Digital Marketing</strong>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className="tf_column">
                    <Lottie resizeMode="cover" animationData={Newsmedia} loop={true} />   
                    <strong className="bold-text">News Media</strong>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
};

export default Experties;
